// =====================================================================
// Typography
// =====================================================================

@mixin font-vag-regular {
  font-family: "vag-rundschrift-d", sans-serif;
  font-style: normal;
  font-weight: 400;
}

@mixin font-vag-regular-italic {
  font-family: "vag-rundschrift-d", sans-serif;
  font-style: italic;
  font-weight: 400;
}

html {
  @include font-smoothing(on);
  font-size: 62.5%;
  // @include media-breakpoint-up(lg) {
  // }
}

body.artzoo {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: $color-black;
  color: $color-white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  @include font-vag-regular;
}

h1 {
  @include font($font-size-h1);
}

h2 {
  @include font($font-size-h2);
  margin-bottom: 30px;
}

h3 {
  text-transform: uppercase;
  @include font($font-size-h3);
}

h4 {
  text-transform: uppercase;
  @include font($font-size-h4);
  margin-bottom: 20px;
}

h5 {
  @include font($font-size-h5);
}

h6 {
  @include font($font-size-h6);
}

p {
  @include font-vag-regular;
  @include font($font-size-base);

  margin-bottom: 15px;
  @include media-breakpoint-up(md) {
    margin-bottom: 30px;
  }
}

a {
  @include font-vag-regular;
}
