// =====================================================================
// Home (home)
// =====================================================================

.home {

  .az-main {
    a {
      text-decoration: none;
    }
  }

}

.hover-highlight {
  .no-touchevents & {
    &:hover {
      .box {
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}