// =====================================================================
// Single Faq ()
// =====================================================================

.single-faq {

  .az-main {
    h1 {
      margin-bottom: 30px;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $color-yellow;
      margin-bottom: 15px;
    }

    a {
      color: $color-white;
      text-decoration: underline;
    }
  }

}