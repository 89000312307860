// ========================================================================
// MIXINS
// ========================================================================

@mixin reset-gutter($gutter) {
  margin-left: -$gutter;
  margin-right: -$gutter;

  & > [class*="col-"] {
    padding-left: $gutter;
    padding-right: $gutter;
  }
}

@mixin reset-button() {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
}

@mixin reset-input() {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
}

@mixin reset-list() {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

@mixin revert-list() {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
  > li {
    display: list-item;
  }
}

@mixin hamburger($width, $height, $thickness, $linecolor) {
  position: relative;
  width: $width;
  height: $height;
  cursor: pointer;

  > div {
    position: absolute;
    left: 0;
    background-color: $linecolor;
    width: 100%;
    height: $thickness;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 50%;
      margin-top: -($thickness / 2);
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }
}

// @include scrollable-gradient-indicator('top', 80px, rgba(0,0,0,1), rgba(0,0,0,0.5), rgba(0,0,0,0));
@mixin scrollable-gradient-indicator(
  $direction,
  $length,
  $point1,
  $point2,
  $point3
) {
  pointer-events: none;
  position: absolute;
  background: -moz-linear-gradient(top, $point1 0%, $point2 50%, $point3 100%);
  background: -webkit-linear-gradient(
    top,
    $point1 0%,
    $point2 50%,
    $point3 100%
  );
  background: linear-gradient(to bottom, $point1 0%, $point2 50%, $point3 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#20282c', endColorstr='#00000000',GradientType=1 );
  @if $direction == "right" {
    right: 0;
    width: $width;
    height: 100%;
  }
  @if $direction == "left" {
    left: 0;
    width: $width;
    height: 100%;
  }
  @if $direction == "top" {
    top: 0;
    width: 100%;
    height: $length;
  }
  @if $direction == "bottom" {
    bottom: 0;
    width: 100%;
    height: $length;
  }
}

// https://maximilianhoffmann.com/posts/better-font-rendering-on-osx
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// To be defined as the base style as 1 time basis ( @include font(1.7, 24, 20) )
// subsequent breakpoints, only adjust font-size ( @include font(2.4), @include font(2.8), @include font(3.2) )
@mixin font($sizeValue: 1.6, $lineHeight: "", $letterSpacing: "") {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
  // font-size: 4vw;
  @if type-of($lineHeight) == "number" {
    line-height: $lineHeight/($sizeValue * 10);
  } @else if $lineHeight == normal {
    line-height: normal;
  } @else {
    // do nothing
  }
  @if type-of($letterSpacing) == "number" {
    letter-spacing: ($letterSpacing/1000) + em;
  } @else if $letterSpacing == normal {
    letter-spacing: normal;
  } @else {
    // do nothing
  }
}

// @include aspect-ratio(16, 9);
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .ar-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.ar-16-9 {
  @include aspect-ratio(16, 9);
}
.ar-4-3 {
  @include aspect-ratio(4, 3);
}
.ar-3-2 {
  @include aspect-ratio(3, 2);
}

// @include loading($w: 10px, $border-w: 2px, $spd: 1.3s, $primary: red, $secondary: green);
@mixin loading(
  $w: 24px,
  $border-w: 4px,
  $spd: 0.9s,
  $primary: darken(#fff, 45%),
  $secondary: darken(#fff, 25%)
) {
  width: $w;
  height: $w;
  margin-top: -($w / 2);
  margin-left: -($w / 2);
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0;
  border-radius: 50%;
  border-style: solid;
  border-width: $border-w;
  border-top-color: $primary;
  border-left-color: $secondary;
  border-bottom-color: $secondary;
  border-right-color: $secondary;
  animation: spinning $spd linear infinite;
}

// @include btn-cancel(20px,100%,1px,transparentize($color-black,0.5));
@mixin btn-cancel($length, $cross-length, $cross-thickness, $cross-color) {
  position: relative;
  width: $length;
  height: $length;
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    position: absolute;
    @include calc("top", "50% - #{($cross-thickness/2)}");
    left: (100-$cross-length)/2;
    width: $cross-length;
    height: $cross-thickness;
    background-color: $cross-color;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

// @include underline(0.5, -2px);
@mixin underline($thickness, $offset) {
  position: relative;
  text-decoration: none;
  &:after {
    content: "";
    position: absolute;
    bottom: $offset;
    left: 0;
    width: 100%;
    height: 0.1em * $thickness;
    background-color: currentColor;
  }
}

// @include underline-hover(0.5, -2px);
@mixin underline-hover($thickness, $offset) {
  position: relative;
  text-decoration: none;
  &:after {
    transition: width 0.3s ease-out;
    content: "";
    position: absolute;
    bottom: $offset;
    left: 50%;
    width: 0;
    height: 0.1em * $thickness;
    background-color: currentColor;
    transform: translateX(-50%);
  }
  .no-touchevents & {
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
}

// keyframe mixin
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

// https://css-tricks.com/snippets/sass/strip-unit-function/
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

// https://unindented.org/articles/trigonometry-in-sass/
@function pow($number, $exp) {
  $value: 1;
  @if $exp > 0 {
    @for $i from 1 through $exp {
      $value: $value * $number;
    }
  } @else if $exp < 0 {
    @for $i from 1 through -$exp {
      $value: $value / $number;
    }
  }
  @return $value;
}
@function fact($number) {
  $value: 1;
  @if $number > 0 {
    @for $i from 1 through $number {
      $value: $value * $i;
    }
  }
  @return $value;
}
@function pi() {
  @return 3.14159265359;
}
@function rad($angle) {
  $unit: unit($angle);
  $unitless: $angle / ($angle * 0 + 1);
  // If the angle has 'deg' as unit, convert to radians.
  @if $unit == deg {
    $unitless: $unitless / 180 * pi();
  }
  @return $unitless;
}
@function sin($angle) {
  $sin: 0;
  $angle: rad($angle);
  // Iterate a bunch of times.
  @for $i from 0 through 10 {
    $sin: $sin + pow(-1, $i) * pow($angle, (2 * $i + 1)) / fact(2 * $i + 1);
  }
  @return $sin;
}
@function cos($angle) {
  $cos: 0;
  $angle: rad($angle);
  // Iterate a bunch of times.
  @for $i from 0 through 10 {
    $cos: $cos + pow(-1, $i) * pow($angle, 2 * $i) / fact(2 * $i);
  }
  @return $cos;
}
@function tan($angle) {
  @return sin($angle) / cos($angle);
}

// apply to body
@mixin debug-breakpoint($version, $text: "", $outline: "") {
  @if $version == 4 {
    @if $text == true {
      &:after {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        font-size: 50px;
        color: black;
        text-shadow: 0 0 2px white;
        pointer-events: none;
        content: "xs";
        @include media-breakpoint-up(sm) {
          content: "sm";
        }
        @include media-breakpoint-up(md) {
          content: "md";
        }
        @include media-breakpoint-up(lg) {
          content: "lg";
        }
        @include media-breakpoint-up(xl) {
          content: "xl";
        }
      }
    }
    @if $outline == true {
      * {
        outline: 1px solid transparentize(grey, 0.75);
        @include media-breakpoint-up(sm) {
          outline: 1px solid transparentize(red, 0.75);
        }
        @include media-breakpoint-up(md) {
          outline: 1px solid transparentize(green, 0.75);
        }
        @include media-breakpoint-up(lg) {
          outline: 1px solid transparentize(blue, 0.75);
        }
        @include media-breakpoint-up(xl) {
          outline: 1px solid transparentize(cyan, 0.75);
        }
      }
    }
  }
  @if $version == 3 {
    @if $text == true {
      &:after {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        font-size: 50px;
        color: black;
        text-shadow: 0 0 2px white;
        pointer-events: none;
        content: "xs";
        @include media-breakpoint-up(sm) {
          content: "sm";
        }
        @include media-breakpoint-up(md) {
          content: "md";
        }
        @include media-breakpoint-up(lg) {
          content: "lg";
        }
      }
    }
    @if $outline == true {
      * {
        outline: 1px solid transparentize(grey, 0.75);
        @include media-breakpoint-up(sm) {
          outline: 1px solid transparentize(red, 0.75);
        }
        @include media-breakpoint-up(md) {
          outline: 1px solid transparentize(green, 0.75);
        }
        @include media-breakpoint-up(lg) {
          outline: 1px solid transparentize(blue, 0.75);
        }
      }
    }
  }
}

@mixin az-button {
  transition: background-color 0.3s $easeOutCubic;
  text-transform: uppercase;
  padding: 0 24px;
  background-color: $color-blue;

  &:hover {
    background-color: darken($color-blue, $state-hover);
  }
}
