// =====================================================================
// Single News (single-new)
// =====================================================================

.single-news_article {

  .content {
    background-color: #0093ca;
  }

  .az-main {
    color: $color-white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $color-white;
    }

    h1 {
      margin-bottom: 0;
    }

    h3,
    h4 {
      color: #ffcb05;
    }

    a {
      color: $color-white;
      text-decoration: underline;
    }
  }

}