// =====================================================================
// Header
// =====================================================================

$hamburger-w: 30px;

.header-nav {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: $color-black;
  margin-bottom: 0;

  @include media-breakpoint-up(md) {
  }

  .navbar {
    padding: 0;
    justify-content: flex-start;
  }

  .navbar-brand {
    position: relative;
    display: block;
    @include make-col(6);
    margin-left: auto;
    margin-right: auto;

    left: -$hamburger-w / 2;
    @include media-breakpoint-up(md) {
      left: 0;
      margin-left: 0;
      max-width: 460px;
    }
  }

  .navbar-collapse {
    padding-left: 0;

    @include media-breakpoint-up(md) {
      @include make-col(6);
      padding-left: 15px;
    }
  }

  .navbar-hamburger {
    @include hamburger($hamburger-w, 20px, 4px, $color-white);

    @include media-breakpoint-up(md) {
      display: none;
    }

    > div {
      transition: width 0.3s $easeInOutCubic;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 2px;
    }

    &[aria-expanded="true"] {
      > div {
        &:nth-child(1) {
          width: 0;
        }

        &:nth-child(3) {
          width: 0;
        }
      }
    }
  }
}

#menu-primary-menu {
  @include make-row();
  color: $color-white;

  display: grid;
  grid-auto-flow: column;
  grid-template: repeat(9, 1fr) / repeat(1, 1fr);
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
    grid-template: repeat(5, 1fr) / repeat(2, 1fr);
  }

  @include media-breakpoint-up(lg) {
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  }

  > li {
    &.active {
      > a {
        color: $color-blue;
      }
    }

    .no-touchevents & {
      &:hover {
        > a {
          color: $color-blue;
        }
      }
    }

    > a {
      display: inline-block;
      transition: color 0.3s $easeOutCubic;
      @include font($font-size-base, 24);
      color: $color-white;
      text-transform: uppercase;
      padding: 0;
    }
  }
}
