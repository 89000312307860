// =====================================================================
// Global
// =====================================================================

html,
body,
.wrap {
  height: 100%;
}

.wrap {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

.container-adapt {
  @include make-container();
  @include media-breakpoint-up(xxl) {
    @include make-container-max-widths();
  }
}

.sec {
  padding-top: 45px;
  padding-bottom: 45px;

  &.l-short {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  &.l-long {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.subnav {
  z-index: 99;

  > .container-adapt {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .heading {
    margin-bottom: 10px;
  }

  h1 {
    @include font($font-size-h1 * 0.75);
    @include media-breakpoint-up(md) {
      @include font($font-size-h1);
    }
  }
}
.subnav-list {
  @include reset-list();
  font-size: 0;
}
.subnav-item {
  transition: font-size 0.3s $easeOutCubic;
  display: inline-block;
  margin-right: 15px;

  & + .subnav-item:before {
    display: none;
  }

  @include font($font-size-base * 0.75);
  @include media-breakpoint-up(md) {
    @include font($font-size-base);
  }
}
a.subnav-link {
  transition: color 0.3s $easeOutCubic;
  text-decoration: none;
}

.video-holder {
  @include aspect-ratio(16, 9);
  iframe {
    width: 100%;
    height: 100%;
  }
}

.square {
  @include aspect-ratio(1, 1);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.square-link {
  width: 100%;
  height: 100%;
  display: block;
  @include font(2.8, 28);
  text-decoration: none;
}

.square-status {
  display: inline-block;
  padding: 5px 20px;
  border-radius: 20px;
  text-transform: uppercase;
  color: $color-white;
  text-align: center;
  margin-bottom: 10px;

  h4 {
    margin: 0;
  }

  &.past {
    background-color: #4c2e84;
  }
  &.ongoing {
    background-color: #ee3147;
  }
  &.upcoming {
    background-color: #f15b2a;
  }
}

// news listing + detail
.wrap {
  .az-main {
    .news-article {
      padding-bottom: 45px;
    }
    .news-type {
      color: #ffcb05;
      margin-bottom: 10px;
    }
    .news-title {
      color: $color-white;
    }
    .news-date {
      color: $color-white;
      margin-bottom: 20px;
    }
    .news-content {
    }
  }
}

.box {
  transition: opacity 0.3s $easeOutCubic;
  margin-bottom: 30px;

  @include media-breakpoint-up(md) {
    margin-bottom: 45px;
  }
}
.box-content {
}

.box-text {
  margin-top: 5px;
  margin-bottom: 5px;

  h2 {
    margin-bottom: 0;
  }
}

.slick-wrap {
  position: relative;
}

.slick-carousel {
  position: relative;

  &.is-loaded {
    > div {
      opacity: 1;
      visibility: visible;
    }
  }

  > div {
    transition: opacity 0.3s 0.2s $easeOutCubic,
      visibility 0.3s 0.2s $easeOutCubic;
    opacity: 0;
    visibility: hidden;
  }
}

.slick-bullet {
  position: absolute;
  @extend .container-adapt;
  left: 0;
  right: 0;

  bottom: 15px;
  @include media-breakpoint-up(md) {
    bottom: 30px;
  }

  .slick-dots {
    > li {
      .single-art_zoo_family & {
        margin-left: 0;
      }
    }
    .single-art_zoo_family & {
      text-align: left;
      @include make-col(12);
      @include make-col-offset(0);
      @include media-breakpoint-up(md) {
        @include make-col(10);
        @include make-col-offset(1);
      }
    }
  }
}

.slick-title-mobile {
  text-align: center;

  display: block;
  @include media-breakpoint-up(sm) {
    display: none;
  }

  .single-collaboration &,
  .single-event &,
  .single-publication &,
  .single-video & {
    position: absolute;
    top: 34px; // agaration
    right: 15px;
  }
}

.az-pagination {
  @include font-vag-regular;
  margin: 30px 0;
  text-align: center;
  @include font($font-size-base);

  > * {
    padding-left: 10px;
    padding-right: 10px;
  }

  span {
    color: $color-blue;
  }

  a {
    color: $color-white;
  }
}

.backtotop {
  transition: opacity 0.3s $easeOutCubic, visibility 0.3s $easeOutCubic,
    color 0.3s $easeOutCubic;
  position: fixed;
  text-align: center;
  cursor: pointer;
  z-index: 9;
  right: 20px;
  bottom: 10px;
  @include font(1.1);
  @include font-vag-regular;

  display: block;
  @include media-breakpoint-up(md) {
    display: none;
  }

  .fa {
    @include font(4);
  }

  .backtotop-text {
    position: relative;
    top: -5px;
    text-transform: uppercase;
  }

  .no-touchevents & {
    &:hover {
      color: darken($color-white, $state-hover);
    }
  }

  visibility: hidden;
  opacity: 0;
  &.is-active {
    visibility: visible;
    opacity: 1;
  }
}

.mailing-list {
  @include font-vag-regular;
  @include font(1.7);

  label {
    margin-bottom: 0;
    height: 32px;
    background-color: $color-white;
    border-radius: 9999px;
  }

  p {
    margin-bottom: 0;
    margin-right: 8px;
  }

  input {
    height: 100%;

    &[type="email"] {
      border: 0;
      border-bottom-left-radius: 9999px;
      border-top-left-radius: 9999px;
      outline: none;
      padding: 0 16px;
    }
  }
}

.az-button {
  @include reset-button;
  @include az-button;
  color: $color-white;
  border-radius: 9999px;
  @include font(1.7);
}

.loader-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: loader-ellipsis-1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 8px;
      animation: loader-ellipsis-2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 32px;
      animation: loader-ellipsis-2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 56px;
      animation: loader-ellipsis3 0.6s infinite;
    }
  }
}

@keyframes loader-ellipsis-1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loader-ellipsis-2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes loader-ellipsis-3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
