// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$state-hover: 20%;

$color-blue: #0098d6;
$color-grey: #7d7d7d;
$color-yellow: #ffcb08;
$color-black: #000000;
$color-white: #ffffff;

$font-size-h1: 4; // 7
$font-size-h2: 3.2; // 4.8
$font-size-h3: 2.8; // 3.4
$font-size-h4: 2.1; // 2.8
$font-size-base: 2.1; // 2.8
$font-size-h5: 1.8; // 2.1
$font-size-h6: 1.3; // 1.6
