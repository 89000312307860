// =====================================================================
// Contact (contact)
// =====================================================================

.contact {

  .content {
    background-color: #6b2978;
  }

  .az-main {
    color: $color-white;

    a {
      color: $color-white;
    }
  }

  .page-header {
    h1 {
      margin-bottom: 45px;
    }
  }

  .map-holder {
    @include aspect-ratio(1,1);
    margin-bottom: 30px;
  }

  #map {
    width: 100%;
    height: 100%;
  }

  textarea {
    resize: none;
  }
}