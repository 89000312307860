// =====================================================================
// Single Art-Zoo Family (single-az-family)
// =====================================================================

.single-art_zoo_family {

  .az-list {
    @include reset-list();
    font-size: 0;
    margin-bottom: 15px;
  }

    .az-item {
      display: inline-block;
      text-align: center;

      width: calc(100% / 13);
      @include media-breakpoint-up(md) {
        width: calc(100% / 26);
      }
    }

      .az-link {
        @include font($font-size-h4, 24);
        color: $color-white;

        &.is-active {
          text-decoration: underline;
        }
      }

  .slick-slide {
    h1 {
      @include font($font-size-h1);
      @include media-breakpoint-up(md) {
        @include font($font-size-h1 * 2);
      }
    }
    p {
      @include font($font-size-base, 28);
      @include media-breakpoint-up(md) {
        @include font($font-size-base * 1.5);
      }
    }
  }
}