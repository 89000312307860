// =====================================================================
// News (news)
// =====================================================================

.news {

  .content {
    background-color: #0093ca;
  }

  .az-main {
    color: $color-white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $color-white;
    }

    h1 {
      margin-bottom: 0;
    }

    h4 {
      color: #ffcb05;
    }

    a {
      text-decoration: none;
    }
  }

  .news-link {
    display: block;

    .no-touchevents & {
      &:hover {
        .news-title {
          text-decoration: underline;
        }
      }
    }
  }
}