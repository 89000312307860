// =====================================================================
// Overwrites
// =====================================================================

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-slide {
  position: relative;
  outline: none;

  &.is-slide-allowance {
    padding-top: 60px;
    padding-bottom: 60px;

    @include media-breakpoint-up(md) {
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }
}

.slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 1;

  &.fa {
    @include font(4);
    cursor: pointer;
    color: $color-black;
  }

  &.fa-angle-left {
    left: $grid-gutter-width / 2;
  }

  &.fa-angle-right {
    right: $grid-gutter-width / 2;
  }
}

.slick-dots {
  color: $color-black;
  position: relative;
  bottom: 0;

  > li {
    width: auto;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;

    p {
      margin: 0;

      @include font($font-size-base * 0.75);
      @include media-breakpoint-up(md) {
        @include font($font-size-base);
      }
    }

    .no-touchevents & {
      &:hover {
        p {
          text-decoration: underline;
        }
      }
    }

    &.slick-active {
      p {
        text-decoration: underline;
      }
    }
  }
}

div.wpforms-container-full {
  .wpforms-form {
    button[type="submit"] {
      transition: background-color 0.3s $easeOutCubic;
      width: 180px;
      height: 50px;
      border-radius: 25px;
      border: none;
      background-color: #f15a29;
      color: $color-white;
      text-transform: uppercase;
      @include font-vag-regular;
      @include font(2.8);
      padding: 0;
      .no-touchevents & {
        &:hover {
          border: none;
          background-color: darken(#f15a29, $state-hover);
        }
      }
    }
  }
}
