// =====================================================================
// Footer
// =====================================================================

.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  color: $color-white;

  p {
    @include font(1.7);
  }

  .copyright {
    margin-bottom: 0;
  }

  .social {
    @include reset-list;
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    li {
      margin-left: 0;
      margin-right: 10px;
      @include media-breakpoint-up(md) {
        margin-left: 10px;
        margin-right: 0;
      }

      &:hover {
        .fa {
          color: $color-white;
          background-color: $color-blue;
        }
      }
    }

    span {
      vertical-align: middle;
      color: $color-white;
      @include font(1.7);
    }

    .fa {
      transition: background-color 0.3s $easeOutCubic, color 0.3s $easeOutCubic;
      vertical-align: middle;
      line-height: 30px;
      width: 30px;
      height: 30px;
      color: $color-black;
      background-color: $color-white;
      border-radius: 50%;
      @include font(1.8);
    }
  }

  // #menu-social-menu {
  //   @include reset-list;

  //   margin-bottom: 15px;
  //   @include media-breakpoint-up(md) {
  //     margin-bottom: 0;
  //   }

  //   > li {
  //     transition: background-color 0.3s $easeOutCubic, color 0.3s $easeOutCubic;
  //     position: relative;
  //     display: inline-block;
  //     @include font(1.8);
  //     text-align: center;
  //     width: 30px;
  //     height: 30px;
  //     color: $color-black;
  //     background-color: $color-white;
  //     border-radius: 50%;

  //     margin-left: 0;
  //     margin-right: 10px;
  //     @include media-breakpoint-up(md) {
  //       margin-left: 10px;
  //       margin-right: 0;
  //     }

  //     .no-touchevents & {
  //       &:hover {
  //         color: $color-white;
  //         background-color: $color-blue;
  //       }
  //     }

  //     > a {
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       bottom: 0;
  //       right: 0;
  //       margin: auto;
  //       display: block;
  //       color: currentColor;
  //       text-decoration: none;

  //       &:before {
  //         margin-left: 4px;
  //       }
  //     }

  //     &.facebook {
  //       > a {
  //         @extend .fa;
  //         @extend .fa-facebook;
  //         line-height: 30px;
  //       }
  //     }

  //     &.instagram {
  //       > a {
  //         @extend .fa;
  //         @extend .fa-instagram;
  //         line-height: 30px;
  //       }
  //     }

  //     &.youtube {
  //       > a {
  //         @extend .fa;
  //         @extend .fa-youtube;
  //         line-height: 30px;
  //       }
  //     }
  //   }
  // }
}
