// ========================================================================
// Helper Classes
// ========================================================================
.counter-reset {
    counter-reset: section;
}
.counter-reset-no {
    &:before {
        counter-increment: section;
        content: counter(section);
    }
}
.cursor-pointer {
    cursor: pointer;
}
.img-full {
    width: 100%;
}
.img-sharpen {
    transform: translateZ(0);
}
.overflow-hidden {
    overflow: hidden;
}
.valign-t {
    vertical-align: top;
}
.valign-m {
    vertical-align: middle;
}
.valign-b {
    vertical-align: bottom;
}
.talign-center {
    text-align: center;
}
.talign-left {
    text-align: left;
}
.talign-right {
    text-align: right;
}
.full-height {
    height: 100%;
}
.full-width {
    width: 100%;
}
.img-sharpen {
    transform: translateZ(0);
}
.tablefix {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
}
.tablefix-cell {
    display: table-cell;
    vertical-align: middle;
}
.tablefix-cell-content {
    position: relative;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.italic {
    font-style: italic;
}
.bold {
    font-style: bold;
}
.underline {
    text-decoration: underline;
}
.float-l {
    float: left;
}
.float-r {
    float: right;
}
// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
.clearfix {
    *zoom: 1;

    &:before,
    &:after {
        display: table;
        content: "";
        // Fixes Opera/contenteditable bug:
        // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
        line-height: 0;
    }

    &:after {
        clear: both;
    }
}
