// =====================================================================
// Artzoo World (az-world)
// =====================================================================

$reset-gutter-spacing: 16px;
$stagger: 0.025s;

.a-z-world {
  .az-world-list {
    @include reset-list();
    @include reset-gutter($reset-gutter-spacing / 2);
  }

  .az-world-item {
    margin-bottom: $reset-gutter-spacing;
  }

  .az-world-link {
    cursor: pointer;
    display: block;
    text-align: center;
    @include aspect-ratio(1, 1);
    background-size: cover;
    background-position: center center;

    &:hover {
      .az-world-content {
        opacity: 1;
      }
    }

    h3 {
      @include media-breakpoint-down(md) {
        @include font(2);
      }
    }

    .loader-ellipsis {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }

    &::after {
      transition: opacity 0.9s $easeOutCubic;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.8);
      opacity: 0;

      // background: linear-gradient(
      //   110deg,
      //   rgba(#000, 0.8) 20%,
      //   rgba(#eee, 0.8) 40%,
      //   rgba(#000, 0.8) 50%
      // );
      // background-size: 200% 100%;
      // animation: 1.2s shine linear infinite;
    }
  }

  .az-world-content {
    transition: opacity 0.3s $easeOutCubic, background-color 0.3s $easeOutCubic;
    opacity: 0;
    text-align: center;
    color: $color-white;
    background-color: rgba($color-black, 0.7);
  }
}

.pepper-detail {
  width: 100%;
  padding-left: $reset-gutter-spacing / 2;
  padding-right: $reset-gutter-spacing / 2;
  margin-bottom: $reset-gutter-spacing;

  h1 {
    @include font(5.6);
    margin-bottom: 32px;
  }

  p {
    @include font(2.8);
  }
}

.pepper-detail-content {
  position: relative;
  padding: 16px;
  @include media-breakpoint-up(lg) {
    padding: 32px;
  }

  .cancel {
    cursor: pointer;
    position: absolute;
    top: $reset-gutter-spacing;
    right: $reset-gutter-spacing;
    @include font(3.2);

    path {
      transition: fill 0.3s $easeOutCirc;
    }

    &:hover {
      path {
        fill: lighten($color-black, $state-hover);
      }
    }
  }
}

.pepper-item {
  &[data-state="PENDING"] {
    .az-world-link {
      .az-world-content {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }

      .loader-ellipsis {
        opacity: 1;
      }
    }
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
