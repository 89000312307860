// =====================================================================
// Single Event (single-collaboration, single-event, single-publication, single-video)
// =====================================================================

.single-collaboration,
.single-event,
.single-publication,
.single-video {

  .heading {
    h1 {
      margin: 0;
    }
  }

  .sec.carousel {
    padding: 0;

    > .container-adapt {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;

      margin-top: 15px;
      @include media-breakpoint-up(md) {
        margin-top: 45px;
      }
    }
  }

  .slick-carousel {
    margin-bottom: 0;
  }

}